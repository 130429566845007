<template>
  <div class="success-section">
    <img
      src="~/assets/img/png/popup/success.png"
      alt="success"
      class="success-img"
    >
    <div class="success-title">Спасибо за обращение!</div>
    <div class="success-text">Заявка успешно отправлена</div>
    <div
      class="success-btn btn primary"
      @click="onClosePopup"
    >
      Хорошо
    </div>
  </div>
</template>

<script setup lang="ts">
import { useServicePopup } from '~/composables/useServicePopup';

const { onCloseServicePopup, onToggleSuccessSection } = useServicePopup();

const onClosePopup = () => {
  onCloseServicePopup();
  onToggleSuccessSection(false);
};

</script>

<style scoped lang="scss">
.success-section {
  display: flex;
  flex-direction: column;
}

.success-img {
  width: 72px;
  height: 72px;
  margin-bottom: 24px;
}

.success-title {
  color: var(--dark-gray);
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.6px;
  font-weight: 600;
  margin-bottom: 10px;
}

.success-text {
  color: var(--light-gray);
  font-size: 15px;
  line-height: 140%;
  letter-spacing: -0.3px;
  margin-bottom: 16px;
}

.success-btn {
  text-transform: uppercase;
  font-size: 20px;
  height: 46px;
  width: 120px;
}
</style>
