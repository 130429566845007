<template>
  <Teleport to="body">
    <div class="modal order-popup__root">
      <div class="modal__body ">
        <button
          class="modal__close"
          @click="onClosePopup"
        >
          <img
            src="~/assets/img/svg/popup/close.svg"
            alt="close icon"
          >
        </button>
        <div v-if="!isSuccessShown">
          <div class="order-popup__title">{{ currentService?.title || 'Заказ услуги' }}</div>
          <div class="order-popup__subtitle">{{ currentService?.heading }}</div>
          <div class="order-popup__text">{{ currentService?.text }}</div>
          <div class="order-popup__form">
            <div class="inputWrapper">
              <label for="name">Ваше имя</label>
              <input
                id="name"
                v-model="state.name"
                placeholder="Имя и фамилия"
                class="input"
              >
              <div
                v-for="(error, index) in $v.name.$errors"
                :key="index"
                class="error-valid"
              >
                {{ error.$message }}
              </div>
            </div>

            <div class="inputWrapper">
              <label for="phone">Ваш email или телефон</label>
              <input
                id="phone"
                v-model="state.phone"
                placeholder="E-mail или телефон"
                class="input"
              >
              <div
                v-for="(error, index) in $v.phone.$errors"
                :key="index"
                class="error-valid"
              >
                {{ error.$message }}
              </div>
            </div>

            <div class="inputWrapper">
              <label for="message">Сообщение</label>
              <input
                id="message"
                v-model="state.message"
                placeholder="Необязательно"
                class="input"
              >
            </div>
            <div class="submit-block">
              <button
                :disabled="isFormSending"
                class="order-popup__btn btn primary"
                @click="sendOrder"
              >
                Отправить
              </button>
              <div class="privacy">Я согласен <NuxtLink to="/privacy">с политикой конфиденциальности и использования запрашиваемых данных</NuxtLink></div>
            </div>
          </div>
        </div>
        <SuccessSection v-if="isSuccessShown" />
      </div>
      <div
        class="modal__overlay"
        @click="onClosePopup"
      ></div>
    </div>
  </Teleport>
</template>

<script setup lang="ts">
import { helpers, required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { reactive } from '#imports';
import { useServicePopup } from '~/composables/useServicePopup';
import { useRoute } from '#app';
import { errorParserServerResponse } from '~/utils';
import SuccessSection from '~/components/popups/SuccessSection.vue';

const isFormSending = ref<boolean>(false);
const { $api, $toast } = useNuxtApp();
const { onCloseServicePopup, currentService, isSuccessShown, onToggleSuccessSection } = useServicePopup();
const $route = useRoute();
interface IState{
	name: string;
	phone: string;
	type: string;
	message?: string;
	url?:string;
}

const state = reactive<IState>({
  name: '',
  phone: '',
  message: '',
  type: 'Заказ товара'
});

const rules = {
  name: {
    required: helpers.withMessage('Обязательно к заполнению', required)
  },
  phone: {
    required: helpers.withMessage('Обязательно к заполнению', required)
  }
};
const $v = useVuelidate(rules, state);

const sendOrder = async (): Promise<boolean> => {
  const isFormCorrect = await $v.value.$validate();
  if (!isFormCorrect) {
    return false;
  }
  isFormSending.value = true;
  const newForm = { ...state };

  newForm.message = `Заказ товара: ${currentService.value?.heading}`;
  newForm.url = `${window.location.host}${$route.fullPath}`;

  if (state.message) {
    newForm.message += `\n Сообщение клиента: ${state.message}`;
  }

  const response = await $api.agent.post('/form/send', { body: { ...newForm } })
    .then((res) => res._data)
    .catch((err) => ({ error: errorParserServerResponse(err.response) }));

  if (response.error) {
    $toast.error(response.error);
    isFormSending.value = false;
    return false;
  }

  onToggleSuccessSection(true);

  // if ($ym && ymSendForm.value) {
  //   $ym('reachGoal', ymSendForm.value);
  // }

  isFormSending.value = false;

  return true;
};

const onClosePopup = () => {
  onCloseServicePopup();
  onToggleSuccessSection(false);
};
</script>

<style scoped lang="scss">
.order-popup__root{
  .modal__body{
    max-width: 550px;
    top: 50%;
    transform: translateY(-50%);
    overflow: unset;
    padding: 40px;
  }
}

.modal__close {
  top: 0;
  right: -51px;
  width: 36px;
  height: 36px;
}

.modal__overlay {
  opacity: 0.8;
  background: #2E5300;
}

.order-popup__title, .order-popup__subtitle{
  color: #202B34;
  font-size: 24px;
  line-height: 120%; /* 28.8px */
  letter-spacing: -0.36px;
  margin-bottom: 4px;
}

.order-popup__subtitle {
  text-transform: uppercase;
  margin-bottom: 16px;
}

.order-popup__text {
  color: var(--light-gray);
  font-family: Uniform-Pro, sans-serif;
  font-size: 15px;
  line-height: 140%;
  letter-spacing: -0.3px;
  margin-bottom: 24px;
}

.order-popup__form{
  display: flex;
  flex-direction: column;
  gap: 28px;
}

.inputWrapper {
  & > label {
    color: #78A513;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    margin-bottom: 14px;
    display: block;
  }

  & > input {
    border-radius: 10px;
    border: 1px solid #CAD5E2;
    background: rgb(255 255 255 / 85%);
    padding: 16px;
    font-size: 20px;

    &::placeholder {
      color: rgb(123 135 149 / 40%);
    }
  }
}

.order-popup__btn{
  max-width: 144px;
  width: 100%;
  height: 48px;
  text-transform: uppercase;
  font-size: 20px;
}

.submit-block {
  display: flex;
  gap: 17px;
  align-items: center;
}

.privacy {
  color: var(--light-gray);
  font-size: 12px;
  line-height: 120%;
  letter-spacing: -0.12px;

  & > a {
    color: var(--black);
    text-decoration: underline;
  }
}

@media (max-width: 859px) {
  .order-popup__root{
    .modal__body{
      padding: 24px;
    }
  }
}

@media (max-width: 640px) {
  .submit-block {
    flex-direction: column;
    align-items: center;
  }

  .privacy {
    text-align: center;
  }

  .order-popup__btn {
    max-width: unset;
  }

  .order-popup__root .modal__body {
    top: 48px;
    transform: unset;

  }

  .modal__close {
    top: -46px;
    left: 50%;
    width: 36px;
    height: 36px;
    transform: translateX(-50%);
  }
}
</style>
